import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout";
import "./style.css"

const ExerciseSlugTemplate = ({ pageContext, data }) => {

  const translations = {
    en: {
      backButton: 'Back to Exercises',
    },
    ch: {
      backButton: '返回保健運動'
    }
  }

  return (
    <Layout
      context={pageContext}
      openGraph={{
        url: pageContext.permalink,
        title: pageContext.title,
        description: pageContext.description,
        images: [
          {
            url: data.firestore.exercise.thumbnail
          }
        ]
      }}
    >
      <main className="my-12 flex justify-between w-full flex-col items-center">
        {/* <img className="mb-6" src={data.firestore.article.thumbnail} alt={data.firestore.article.title}/> */}
        <div dangerouslySetInnerHTML={{__html: data.firestore.exercise.video}}></div>
        <div className="mt-16 prose-sm lg:prose mx-8" dangerouslySetInnerHTML={{__html: data.firestore.exercise.content}}>
          
        </div>
        <div className="mt-8 flex space-x-4">
          <a href={`/${pageContext.locale}/about-chiropractic/exercises`} className="text-white bg-brick rounded-md px-6 py-3">{translations[pageContext.locale]['backButton']}</a>
        </div>
      </main>
    </Layout>
  );
}

export default ExerciseSlugTemplate;

export const query = graphql`
  query ExerciseSlugTemplateQuery($pageId: ID!, $locale: String!) {
    firestore {
      exercise(id: $pageId,  locale: $locale) {
        id
        video
        content
      }
    }
  }
`